import { breakpoints, gridGutter } from "@olmokit/core/scss";
import { $, $all, on, forEach, addClass } from "@olmokit/dom";
import { datalayerProdSlider, datalayerProd } from "components/Datalayer";
import {
  glide,
  Anchors,
  Autoplay,
  Breakpoints,
  Controls,
  // LazyLoad,
  Swipe,
} from "@olmokit/core/glide";
import "@olmokit/core/glide/arrows";
import { CardProduct } from "components/CardProduct";
import "./index.scss";

/**
* Component: VisualProducts
*/
export default function products(rootSelector) {

  CardProduct();

  const cardImgs = $all(".VisualProducts:slideImg");
  const cardBtns = $all(".VisualProducts:slideCta");
  
  if (cardImgs) {
    for(let i=0; i < cardImgs.length; i++){
      on(cardImgs[i], "click", () => {
        let dest = cardImgs[i].getAttribute('data-href');
        if (dest) {
          if (cardImgs[i].getAttribute('data-blank') == 'true') {
            if (cardImgs[i].getAttribute('data-product') != '') {
              datalayerProd(cardImgs[i].getAttribute('data-product'), 'false', 'false');
            }
            window.open(dest, '_blank', 'noopener').focus();
          } else {
            window.location.href = dest;
          }
        }
      });
    }
  }
  if (cardBtns) {
    for(let i=0; i < cardBtns.length; i++){
      on(cardBtns[i], "click", () => {
        let dest = cardBtns[i].getAttribute('data-href');
        if (dest) {
          if (cardBtns[i].getAttribute('data-blank') == 'true') {
            window.open(dest, '_blank', 'noopener').focus();
          } else {
            window.location.href = dest;
          }
        }
      });
    }
  }
  
  datalayerProdSlider(rootSelector, 'gtm-prod', 'false');
  
  let slidelength = $(rootSelector).getAttribute('data-slide');
  const width = window.innerWidth;
  // console.log(rootSelector+'; slidelength:'+slidelength+' width:'+width);

/*   switch (parseInt(slidelength)) {
    case 1:
      notSlider(rootSelector);
      break;
    case 2:
      if (width <= 480) {
        startSlider(rootSelector);  
      } else {
        notSlider(rootSelector);
      }
      break;
    case 3:
      if (width <= breakpoints.sm) {
        startSlider(rootSelector);  
      } else {
        notSlider(rootSelector);
      }
      break;
    case 4:
      if (width <= breakpoints.md) {
        startSlider(rootSelector);  
      } else {
        notSlider(rootSelector);
      }
      break;
  
    default:
      startSlider(rootSelector);
      break;
  } */

  if (parseInt(slidelength) == 1 ) {
    notSlider(rootSelector);
  } else if (parseInt(slidelength) == 2 ) {
    if (width <= 480) {
      startSlider(rootSelector);  
    } else {
      notSlider(rootSelector);
    }
  } else if (parseInt(slidelength) == 3 ) {
    if (width <= breakpoints.sm) {
      startSlider(rootSelector);  
    } else {
      notSlider(rootSelector);
    }
  } else if (parseInt(slidelength) == 4 ) {
    if (width <= breakpoints.md) {
      startSlider(rootSelector);  
    } else {
      notSlider(rootSelector);
    }
  } else if (parseInt(slidelength) >= 5 ) {
    startSlider(rootSelector);
  }
  
}


function notSlider(element) {
  // console.log('not slider');
  const root = $(element);
  addClass(root, 'not-slider');
}

function startSlider(element) {
  // console.log('slider');
  const $container = `${element ? element + " " : ""}`;
  const slider = glide(
    $container, {
      loop: true,
      autoplay: 4500,
      hoverpause: true,
      animationDuration: 900,
      killWhen: { above: 99999 },
      bound: true,
      breakpoints: {
        [breakpoints.over]: {
          perView: 5,
          gap: gridGutter,
          peek: { before: gridGutter / 2, after: gridGutter / 2 },
        },
        [breakpoints.xxl]: {
          perView: 5,
          gap: gridGutter,
          peek: { before: gridGutter / 2, after: gridGutter / 2 },
        },
        [breakpoints.xl]: {
          perView: 4,
          gap: gridGutter,
          peek: { before: gridGutter / 2, after: gridGutter * 2 },
        },
        [breakpoints.lg]: {
          perView: 3,
          gap: gridGutter,
          peek: { before: gridGutter / 2, after: gridGutter * 2 },
        },
        [breakpoints.md]: {
          perView: 2,
          gap: gridGutter,
          peek: { before: gridGutter / 2, after: gridGutter * 2 },
        },
        [breakpoints.sm]: {
          perView: 2,
          gap: gridGutter,
          peek: { before: gridGutter / 2, after: gridGutter * 2 },
        },
        [480]: {
          perView: 1,
          gap: gridGutter,
          peek: { before: gridGutter / 2, after: gridGutter * 2 },
        },
      },
    }, { 
      Anchors, 
      Autoplay, 
      Breakpoints, 
      Controls,
      // LazyLoad, 
      Swipe 
    }
  );
  return slider;
}
