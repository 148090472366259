import './Result.scss';
// import Button from '../../Components/button/index';
import { $, $all, on } from "@olmokit/dom";
import { post } from "@olmokit/core/fragments/ajax";
// import LazyLoad from "@olmokit/core/lazy";
import { feedSearch, setQuery, urlQuery } from './Search';

export default function Result(value = null) {

  if(value){
    feedSearch(value);
    makeRequest(value);
  }

  function makeRequest(label, start = '') {

    const $loader = $('.Searchresult:loader');
    const $result = $('.Searchresult:result');
    $loader.style.display = "flex";
    $result.style.opacity = '0.5';

    post("search", {
      data: {
        terms: label,
        start: start
      },
    }).then(({ data }) => {
      const resultlist = start != '' ? $all('.Searchresult:result') : $result;
      $loader.style.display = "none";
      $result.style.opacity = "1";

      if(start != ''){
        resultlist[resultlist.length - 1].innerHTML = data;
      } else {
        resultlist.innerHTML = data;
      }

      // new LazyLoad();

      const $resultLength = $('#numberResult').innerHTML;
      dataLayer.push({
        'event': 'internal research',
        'querySearched': label,
        'results': $resultLength.replace('&nbsp;', '')
      });      

      labels();
      clickLoadMore();
    });

  }

  function labels() {

    const labels = document.getElementsByClassName('labels:label');

    for (let i = 0; i < labels.length; i++) {

      on(labels[i], 'click', (e) => {
        const value = e.target.getAttribute('data-label')
        makeRequest(value);
        feedSearch(value);
        setQuery(e.target.getAttribute('data-label'));
      });

    }

  }

  function clickLoadMore() {

    let loadmore = $('.loadmore');
    const $indexPage = $all('.Result:nextpagecoutner');

    if($indexPage[$indexPage.length - 1].value == '0'){
      loadmore.style.display = "none";
    }

    on(loadmore, 'click', (e) => {
      e.preventDefault();
      let startdata = $all('.Searchresult:result');
      const query = urlQuery();
      makeRequest(query, startdata[startdata.length - 1].getAttribute('data-search'));
    });

  }


}