import { QRcode } from "components/QRcode";
import "./index.scss";

/**
 * Component: Footer
 */
export function Footer() {
  console.log("Footer mounted.");

  QRcode();

}

