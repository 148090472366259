import { $all, on } from "@olmokit/dom";
import "./index.scss";
// import { $ } from "@olmokit/dom";

/**
 * Component: VisualHighlights
 */
export function VisualHighlights() {
  console.log("VisualHighlights mounted.");

  const cardBtns = $all(".VisualHighlights:linkCta");
  
  if (cardBtns) {
    for(let i=0; i < cardBtns.length; i++){
      on(cardBtns[i], "click", () => {
        let dest = cardBtns[i].getAttribute('data-href');
        if (cardBtns[i].getAttribute('data-blank') == 'true') {
          window.open(dest, '_blank', 'noopener').focus();
        } else {
          window.location.href = dest;
        }
      });
    }
  }

}
