import "./Property.scss";
import { $, $all, forEach, on, removeClass } from "@olmokit/dom";
import FormsSelectMaterial from "@olmokit/core/forms/select/material";
import { makeThePost } from "components/Form/Product";

/**
 * Component: FormProductCart
 */
export function FormProductProps() {
  console.log("FormProductProps mounted.");

  FormsSelectMaterial();
  const property = $('.FormProductDetail:property');

  const $container = $all('.FormProductProps::');
  // const $button = $('button', $container);

  // forEach($container, (product, index) => {
    const $button = $('.FormProductProps:addproduct');
    const $delete = $('.FormProductProps:deleteproduct');
    const $finish = $('#of-0-finish');
    const $thickness = $('#of-0-thickness');
    const $size = $('#of-0-size');

    on($finish, 'change', (event) => {
      if(event.target.value != ''){
        console.log(event.target.value);
        // $button.style.display = 'block';
        const $productInput = $('#of-9-products');
        const val = $productInput.value;
        const covnert = JSON.parse(val);
        covnert[0]['finish'] = event.target.value; 
        $productInput.value = JSON.stringify(covnert);
      }
    });
    on($thickness, 'change', (event) => {
      if(event.target.value != ''){
        console.log(event.target.value);
        // $button.style.display = 'block';
        const $productInput = $('#of-9-products');
        const val = $productInput.value;
        const covnert = JSON.parse(val);
        covnert[0]['thickness'] = event.target.value; 
        $productInput.value = JSON.stringify(covnert);        
      }      
    });
    on($size, 'change', (event) => {
      if(event.target.value != ''){
        console.log(event.target.value);
        // $button.style.display = 'block';
        const $productInput = $('#of-9-products');
        const val = $productInput.value;
        const covnert = JSON.parse(val);
        covnert[0]['size'] = event.target.value; 
        $productInput.value = JSON.stringify(covnert);          
      }      
    });

    on($button, 'click', () => {
      makeThePost(index + 1);
    });
    on($delete, 'click', () => {
      const $content = $all('.productCoutnauner');
      $content[index].remove();
    });
  // });

}
