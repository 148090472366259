import { $, $all, on } from "@olmokit/dom";
import scrollLock from "@olmokit/core/scroll/lock";
import "./index.scss";
// import { $ } from "@olmokit/dom";

/**
* Component: MenuDesktop
*/
export function MenuDesktop() {
  console.log("MenuDesktop mounted.");
  
  // get all ctas and all hidden menus
  const menuBtn = $all("[data-opener]");

  if (menuBtn) {
    // check if clicked element is an opener and get id to open destination menu, if not close all menus
    document.addEventListener('click', function (event) {
      if (event.target.attributes.getNamedItem('data-opener')) {
        let id = event.target.getAttribute('data-id');
        // first of all remove class from all other open menus
        let menuFolders = $all("[data-folder].is-in");
        if (menuFolders) {
          for(let i=0; i < menuFolders.length; i++){
            menuFolders[i].classList.remove('is-in');
          }; 
        }
        // apply class to menu with specified id
        let dest = $("#"+id);
        dest.classList.add('is-in');   
        scrollLock.disable(document.body);
        let headerBelow = $(".Header:.threshold--below");
        if (headerBelow) {
          headerBelow.classList.remove('threshold--below');
          headerBelow.classList.add('threshold--above');
        }
        let headerDark = $(".Header:.is-dark");
        if (headerDark) {
          headerDark.classList.add('is-inverted');
        }
      } else {
        let open = $(".MenuDesktop:primaryFolder.is-in");
        if (open) {
          closeMenu(open);
        }
      }
    });
    // use keyboard to close menu
    document.addEventListener('keydown', evt => {
      if (evt.key === 'Escape') {
        let open = $(".MenuDesktop:primaryFolder.is-in");
        if (open) {
          closeMenu(open);
        }
      }
    });
  }  
  
}

function closeMenu(folder) {
  // remove class from open menu
  scrollLock.enable(document.body);
  folder.classList.remove('is-in');
  let headerAbove = $(".Header:.threshold--above");
  if (headerAbove) {
    headerAbove.classList.remove('threshold--above');
    headerAbove.classList.add('threshold--below');
  }
  let headerDark = $(".Header:.is-dark");
  if (headerDark) {
    headerDark.classList.remove('is-inverted');
  }
}
