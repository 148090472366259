import { $all, on } from "@olmokit/dom";
import "./index.scss";

/**
 * Component: CardBlog
 */
export function CardBlog() {
  console.log("CardBlog mounted.");

  const cardImgs = $all(".CardBlog:img");
  const cardBtns = $all(".CardBlog:cta");
  
  if (cardImgs) {
    for(let i=0; i < cardImgs.length; i++){
      on(cardImgs[i], "click", () => {
        let dest = cardImgs[i].getAttribute('data-href');
        window.location.href = dest;
      });
    }
  }
  if (cardBtns) {
    for(let i=0; i < cardBtns.length; i++){
      on(cardBtns[i], "click", () => {
        let dest = cardBtns[i].getAttribute('data-href');
        window.location.href = dest;
      });
    }
  }

}
