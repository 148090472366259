import { breakpoints, gridGutter } from "@olmokit/core/scss";
import { $, $all, addClass, forEach } from "@olmokit/dom";
import "./Minimal.scss";

/**
 * Component: Configurator
 */

 function sliders() {

  // console.log('dajeeee');

  import("@olmokit/core/glide").then(({glide, Anchors, Breakpoints, Controls, /* LazyLoad, */ Autoplay, Swipe}) => {
    const glideOptions = {
      loop: false,
      perView: 5,
      gap: gridGutter,
      breakpoints: {
        1440: {
          perView: 5,
          gap: gridGutter,
          peek: { before: gridGutter / 2, after: gridGutter / 2 },
        },
        1280: {
          perView: 4,
          gap: gridGutter,
          peek: { before: gridGutter / 2, after: gridGutter * 2 },
        },
        992: {
          perView: 3,
          gap: gridGutter,
          peek: { before: gridGutter / 2, after: gridGutter * 2 },
        },
        768: {
          perView: 2,
          gap: gridGutter,
          peek: { before: gridGutter / 2, after: gridGutter * 2 },
        },
        [480]: {
          perView: 1,    
          gap: gridGutter,
          peek: { before: gridGutter / 2, after: gridGutter * 2 }   
        },
      },
      // killWhen: { above: 99999 },
      // bound: true,
      // breakpoints: {
      //   [breakpoints.over]: {
      //     perView: 5,
      //     gap: gridGutter,
      //     peek: { before: gridGutter / 2, after: gridGutter / 2 },
      //   },
      //   [breakpoints.xxl]: {
      //     perView: 5,
      //     gap: gridGutter,
      //     peek: { before: gridGutter / 2, after: gridGutter / 2 },
      //   },
      //   [breakpoints.xl]: {
      //     perView: 4,
      //     gap: gridGutter,
      //     peek: { before: gridGutter / 2, after: gridGutter * 2 },
      //   },
      //   [breakpoints.lg]: {
      //     perView: 3,
      //     gap: gridGutter,
      //     peek: { before: gridGutter / 2, after: gridGutter * 2 },
      //   },
      //   [breakpoints.md]: {
      //     perView: 2,
      //     gap: gridGutter,
      //     peek: { before: gridGutter / 2, after: gridGutter * 2 },
      //   },
      //   [breakpoints.sm]: {
      //     perView: 2,
      //     gap: gridGutter,
      //     peek: { before: gridGutter / 2, after: gridGutter * 2 },
      //   },
      //   [480]: {
      //     perView: 1,
      //     gap: gridGutter,
      //     peek: { before: gridGutter / 2, after: gridGutter * 2 },
      //   },
      // },
    };  

    const slider = $(".ConfiguratorMinimal:slider");
  
    const sliderImages = glide(slider, glideOptions);
  
    sliderImages.mount({
        // LazyLoad,
        Anchors,
        Controls,
        Autoplay,
        Breakpoints
    });

  }); 
}

function notsliders(el) {
  console.log('notsliders');
  const $arrows = $('.glide__arrows', el);
  const $slides = $('.glide__slides', el);
  const $slide = $all('.glide__slide', el);
  addClass(el, 'not-slider');
  addClass($arrows, 'arrow');
  addClass($slides, 'slides');
  forEach($slide, (slide) => {
    addClass(slide, 'slide');
  });
}

export function ConfiguratorMinimal() {
  console.log("ConfiguratorMinimal mounted.");
  const $slider = $(".ConfiguratorMinimal:slider");
  if($slider){
    const slidelength = $slider.getAttribute('data-slide');
    const width = window.innerWidth;
  
    // if(parseInt(slidelength) > 5 && width >= 1440){
    //   sliders();
    // } else if(parseInt(slidelength) > 4 && width <= 1280) {
    //   sliders();
    // } else if(parseInt(slidelength) > 3 && width <= 992) {
    //   sliders();
    // } else if(parseInt(slidelength) > 2 && width <= 768) {
    //   sliders();
    // } else if(parseInt(slidelength) > 1 && width <= 480) {
    //   sliders();
    // } else {
    //   notsliders($slider);
    // }

    if (parseInt(slidelength) == 1 ) {
      notsliders($slider);
    } else if (parseInt(slidelength) == 2 ) {
      if (width <= 480) {
        sliders();  
      } else {
        notsliders($slider);
      }
    } else if (parseInt(slidelength) == 3 ) {
      if (width <= breakpoints.sm) {
        sliders();  
      } else {
        notsliders($slider);
      }
    } else if (parseInt(slidelength) == 4 ) {
      if (width <= breakpoints.md) {
        sliders();  
      } else {
        notsliders($slider);
      }
    } else if (parseInt(slidelength) >= 5 ) {
      sliders();
    }

  }
}