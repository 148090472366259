/**
 * @file Route: home
 *
 * This file is compiled as a standalone entrypoint and it is included by
 * default in its blade template.
 * This is a "standard" route, it does not have a structure to follow, you are
 * sure the DOM is always ready and the JS will only be executed in this route.
 */

import "layouts/main";
import { $, $all } from "@olmokit/dom";
import { globalData } from "@olmokit/core/data";
import { VisualVideo } from "components/VisualVideo";
 import { VisualPlus } from "components/VisualPlus"; 
import "components/VisualDownload";
import projects from "components/VisualProjects";
import { VisualHighlights } from "components/VisualHighlights";
import products from "components/VisualProducts";
import "components/VisualPhoto";
import "components/VisualQuote";
import "components/VisualText";
import slider from "components/HomeSlider";
import { Homeslider } from "components/HomeSlider";
import "components/VisualBox";
import "components/VisualTwin";
import "components/VisualAccordion";
import { replaceFragment } from "@olmokit/core/fragments";
import { FormType } from "components/Form";
import "components/Related";

import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

import "./index.scss";

console.log("Route home/index.js mounted.");

const cf = $('.id-contactForm');
if (cf) {
    replaceFragment("contactForm", "components.FormType").then(() => FormType());
    // FormType();
}

const glideEl = $all(".glide");
if (glideEl.length > 0) {
    for (let item of globalData.sliders) {
        if (item.name == 'slider')  {
            slider('.HomeSlider:slider_'+item.id);
        }
        else if (item.name == 'looks' || item.name == 'products')  {
            products('.VisualProducts:slider_'+item.id);
        }
    }
    if (globalData.sliderprojects) {
        projects('.VisualProjects:'+globalData.sliderprojects);
    }
    if (globalData.sliderblog) {
        projects('.VisualProjects:'+globalData.sliderblog);
    }
}

VisualPlus();
VisualVideo();
Homeslider();

VisualHighlights();

//Scrolling socials
const socials = $(".home:social");
const showAnim = gsap.from(socials, { 
    top: 71,
    paused: true,
    duration: 0.3
}).progress(1);

ScrollTrigger.create({
    start: "top top",
    end: 99999,
    onUpdate: (self) => {
    self.direction === -1 ? showAnim.play() : showAnim.reverse()
    }
});