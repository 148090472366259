import { $, $all, on } from "@olmokit/dom";
import scrollLock from "@olmokit/core/scroll/lock";
import "./index.scss";
// import { $ } from "@olmokit/dom";

/**
* Component: QRcode
*/
export function QRcode() {
  console.log("QRcode mounted.");
  
  /* Open qrcodes */
  
  // get all ctas and all hidden popups
  const qrBtn = $all(".QRcode:btn");
  const qrClose = $all(".QRcode:bg");
  if (qrBtn) {
    // cycle through all btns and get name from id
    for(let i=0; i < qrBtn.length; i++){
      on(qrBtn[i], "click", () => {
        let id = qrBtn[i].getAttribute('data-qrcode');
        // apply class to popup with specified id
        let dest = $("#qrcode-"+id);
        dest.classList.add('is-in');   
        scrollLock.disable(document.body);
      });
    }
  }
  
  if (qrClose) {
    // cycle through all close btns
    for(let i=0; i < qrClose.length; i++){
      on(qrClose[i], "click", () => {
        // remove class from open popup
        scrollLock.enable(document.body);
        let open = $(".QRcode:.is-in");
        open.classList.remove('is-in');
      });
    }
  }

  
}
