import { $all, on } from "@olmokit/dom";
import "./index.scss";
// import { $ } from "@olmokit/dom";

/**
 * Component: CardProject
 */
export function CardProject() {
  console.log("CardProject mounted.");

  const cardImgs = $all(".CardProject:img");
  
  if (cardImgs) {
    for(let i=0; i < cardImgs.length; i++){
      on(cardImgs[i], "click", () => {
        let dest = cardImgs[i].getAttribute('data-href');
        window.location.href = dest;
      });
    }
  }

}
