import Mmenu from "mmenu-js/src/mmenu";
import "mmenu-js/src/mmenu.scss";
import { $all, $, on, forEach, addClass, removeClass } from "@olmokit/dom";
import { globalConf } from "@olmokit/core/data";
import { post } from "@olmokit/core/fragments/ajax";
import "./index.scss";


/**
 * Component: Menu
 */
export function MenuMobile() {
  console.log("Menu mounted.");

  const $root = $(".Header:");
  const $toggle = $(".Header:mmenutoggle", $root);
  let status = "collapsed";
  on($toggle, "click", handleToggle);
  on(document, "click", handleClickOutside);
  /**
   * Handle toggle
   */
  function handleClickOutside(event) {
    const $doc = event.target.id;
    if ($doc == 'mm-0') {
      removeClass($toggle, "is-active");
    }
  }
  function handleToggle() {
    if (status !== "expanded") {
      expand();
    } else {
      collapse();
    }
  }
  function expand() {
    addClass($toggle, "is-active");
  }
  function collapse() {
    removeClass($toggle, "is-active");
  }

  on(document, "DOMContentLoaded", () => {

    const menu = new Mmenu("#menu", {
      // navbar: {
      //   title: "My contacts"
      // },
      // searchfield: {
      //   add: true,
      //   addTo: "#contacts"
      // },
      offCanvas: {
        position: "left-front"
      },
      navbars: [{
        height: 2,
        content: [
          '<div class="MenuMobile:"><div class="MenuMobile:logo"><img draggable="false" alt="" style="width:139px" width="139" height="25" src="' + globalConf.assetsUrl + 'media/atlas-plan-logo-dark.png" loading="lazy" /></div>' +
          '<div class="MenuMobile:icons"><div class="MenuMobile:icons-search"><a href="' + globalConf.baseUrl + '/' + globalConf.locale + '/search/"><img draggable="false" alt="Search" style="width:18px; height:18px" width="18" height="18" src="' + globalConf.assetsUrl + 'media/search.png" loading="lazy" /></a></div></div></div>'
                 ]
      }]
    }, {});

    setTimeout(() => {
      const $container = $('.MenuMobile:icons');
      const $wishlist = $('.Wishlist:', $container);
      const $cart = $('.ConfiguratorCart:');
      const $cartOverlay = $('.ConfiguratorCart:overlay');
  
      const api = menu.API;
  
      on($wishlist, 'click', () => {
        api.close();
        removeClass($toggle, "is-active");
        setTimeout(() => {
          addClass($cart, 'is-opencart');
          addClass(document.body, 'is-blocked');
          addClass($cartOverlay, 'is-opencaroverlay');
        }, 300);
      });
    }, 2000);

    /**
     * Open the menu programmatically
     */
    // const hamburger = $('.Header:toggle');
    // on(hamburger, 'click', () => {
    //   api.open();
    // });

  });

}
