import FormsSelectMaterial from "@olmokit/core/forms/select/material";
import Olmoforms from "@olmokit/core/olmoforms/feedback";
import Dependency from "@olmokit/core/olmoforms/dependency";
import { $, on } from "@olmokit/dom";
import { post } from "@olmokit/core/fragments/ajax";
import { postCart, itemIntheCart, deleteItem } from "components/Configurator";
import { FormOther, FormProduct } from "components/Form";
import { dataLayerFillingType } from "components/Datalayer";
import "./Type.scss";

/**
* Component: FormProductCart
*/
export function FormType() {
  console.log("FormType mounted.");
  
  const olmoforms = Olmoforms(".FormType:");
  
  Dependency(".FormType:");
  FormsSelectMaterial();
  
  let user = 'business';
  let form = 'other';
  let forms = FormOther();
  // let forms = FormProduct();
  
  const $type = $('#of-3-requesttype');
  on($type, 'change', (event) => {
    console.log('event', event.target.value);
    dataLayerFillingType(event.target.name);
    form = event.target.value;
    forms.destroy();
    makeThePost(form, user);
  });
  
  const $user = $('#of-3-usertype');
  on($user, 'change', (event) => {
    console.log('event', event.target.value);  
    dataLayerFillingType(event.target.name);
    user = event.target.value;
    forms.destroy();
    makeThePost(form, user);
  });
  
  function makeThePost(form, user) {
    post('forms', {
      data: {
        form: form,
        user: user
      },
    }).then(({ data }) => {
      
      if(form == 'collaboration'){
        import("components/Form").then(({ FormCollaboration }) => {
          forms = FormCollaboration();          
        });
      } else if(form == 'other'){
        import("components/Form").then(({ FormOther }) => {
          forms = FormOther();
        });
      } else if(form == 'material'){
        import("components/Form").then(({ FormMaterial }) => {
          forms = FormMaterial();
        });
      } else if(form == 'dealer'){
        import("components/Form").then(({ FormDealer }) => {
          forms = FormDealer();
        });
      } else if(form == 'product'){
        import("components/Form").then(({ FormProduct }) => {
          forms = FormProduct();
        });
      }
      
      const $forms = $('.Forms:');
      $forms.innerHTML = data;
    });     
  }
  
  return {
    destroy() {
      olmoforms.destroy();
    },
  };
  
  
}
