import "./InputMenu.scss";
import { $, on } from "@olmokit/dom";
import gsap from "gsap";
import Search from "./Search";

/**
 * Component: Search
 */

export function closeCleanSearch() {
  // const $searchopen = $('.Search:open');
  // const $searchclose = $('.Search:close');
  // const $searchWrap = $('.Searchbarheader:wrap');
  const $searchbarheader = $('#searchbarheader');

  // $searchopen.style.display = 'block';
  // $searchclose.style.display = 'none';
  // gsap.to($searchWrap, { duration: 0.1, width: 0 });
  $searchbarheader.value = '';
}

export default function InputMenu() {
  console.log("InputMenu mounted.");

  // const $searchopen = $('.Search:open');
  // const $searchclose = $('.Search:close');
  // const $searchWrap = $('.Searchbarheader:wrap');
  // const $searchbarheader = $('#searchbarheader');

  // on($searchopen, 'click', () => {
    // $searchopen.style.display = 'none';
    // $searchclose.style.display = 'block';
  //   gsap.to($searchWrap, { duration: 0.1, width: 400 });
  //   $searchbarheader.focus();
  // });

  // on($searchclose, 'click', () => {
    // $searchopen.style.display = 'block';
    // $searchclose.style.display = 'none';
    // gsap.to($searchWrap, { duration: 0.1, width: 0 });
  // });

  Search('#searchbarheader', '#buttonsearchbarheader');

}

