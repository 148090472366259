// import { $ } from "@olmokit/dom";
import "./index.scss";

/**
 * Component: VisualVideo
 */
export function VisualVideo() {
  console.log("VisualVideo mounted.");

  import("@olmokit/core/player").then(({ Player }) => {
    Player();    
  });
}
