import { $, $all, on, forEach, addClass } from "@olmokit/dom";
import "./index.scss";
// import { $ } from "@olmokit/dom";

/**
 * Component: VisualPlus
 */
export function VisualPlus() {
  console.log("VisualPlus mounted.");

  import("@olmokit/core/player").then(({ Player }) => {
    Player();    
  });

  const blockBtns = $all(".VisualPlus:columnCta--href");

  if (blockBtns) {
    for(let i=0; i < blockBtns.length; i++){
      on(blockBtns[i], "click", () => {
        let dest = blockBtns[i].getAttribute('data-href');
        if (blockBtns[i].getAttribute('data-blank') == 'true') {
          window.open(dest, '_blank', 'noopener').focus();
        } else {
          window.location.href = dest;
        }
      });
    }
  }

}
