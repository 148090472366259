import FormsInputMaterial from "@olmokit/core/forms/input/material";
import "@olmokit/core/forms/checkbox";
import Olmoforms from "@olmokit/core/olmoforms/feedback";
import Dependency from "@olmokit/core/olmoforms/dependency";
import { $, $all, on, removeClass } from "@olmokit/dom";
import { AjaxConfig, Ajax } from "@olmokit/core/ajax";
import { dataLayerFillingForm, dataLayerNewsForm } from "components/Datalayer";
import { globalConf } from "@olmokit/core/data";
import { salesforceCommunication } from "components/Form";
import axios from 'axios';
import "./Newsletter.scss";

/**
 * Component: FormNewsletter
 */
export function FormNewsletter() {
  console.log("FormNewsletter mounted.");

  dataLayerFillingForm('.of:el', 'Form Newsletter')

  const olmoforms = Olmoforms(".FormNewsletter:", {
    sent: (formData) => {
      dataLayerNewsForm("form sent", formData);
      salesforceCommunication(formData, "Newsletter");
    }, 
    failed: (instance) => {
      dataLayerNewsForm("form_error", '')
    } 
  });

  FormsInputMaterial();
  Dependency(".FormNewsletter:");

  return {
    destroy() {
      olmoforms.destroy();
    },
  };


}
