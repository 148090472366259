import { post } from "@olmokit/core/fragments/ajax";
import { $, $all, on, addClass, removeClass, forEach } from "@olmokit/dom";
import { ConfiguratorCartItem } from "./CartItem";
// import LazyLoad from "@olmokit/core/lazy";
import { device } from "@olmokit/core/detect";
import { dataLayerWishlist } from "components/Datalayer";
import { sessionCheck } from "components/Popup";
import "./Cart.scss";

/**
 * Component: ConfiguratorCart
 */

export function ConfiguratorCart() {
  console.log("ConfiguratorCart mounted.");

  const $addToCart = $('#addToCart');
  const $close = $('.ConfiguratorCart:top_title svg');
  const $closebutton = $('.ConfiguratorCart:bottom_continue-button');
  const $cart = $('.ConfiguratorCart:');
  const $wishlist = $('.Wishlist:');
  const $form = $('.ConfiguratorCart:form');  

  // const $itemListcart = $('.ConfiguratorCart:center_list');
  // const height = window.innerHeight - 78 - 119 - 220;
  // $itemListcart.style.height = height+'px';
  
  const $cartOverlay = $('.ConfiguratorCart:overlay');

  /**
   * Cloen the Wishlist div and pase it in to mobile version menu
   */
  const desktop = !device().any;  
  if(!desktop){
    const wishlist = $('.Wishlist:'); 
    const clone = wishlist.cloneNode(true); 
    setTimeout(() => {
      const wishlistMobile = $('.MenuMobile:icons-search');  
      wishlistMobile.after(clone);
      /**
       * Watch out this is tricky, the action of this 
       * method is in the MobileMenu index.ts file at line 73
       */
    },2000)
  }

  on($close, 'click', () => {
    removeClass($form, 'is-openform');
    setTimeout(() => {
      removeClass($cart, 'is-opencart');
      removeClass(document.body, 'is-blocked');
      removeClass($cartOverlay, 'is-opencaroverlay');
    }, 300);
    sessionCheck();
  });

  on($closebutton, 'click', () => {
    removeClass($form, 'is-openform');
    setTimeout(() => {
      removeClass($cart, 'is-opencart');
      removeClass(document.body, 'is-blocked');
      removeClass($cartOverlay, 'is-opencaroverlay');
    }, 300);
    sessionCheck();
  });

  on($wishlist, 'click', () => {
    addClass($cart, 'is-opencart');
    addClass(document.body, 'is-blocked');
    addClass($cartOverlay, 'is-opencaroverlay');
  });

  on($addToCart, 'click', () => {
    postCart();
  });

  ConfiguratorCartItem();

  deleteItem();

}

export function postCart() {
  const $minimal = $('.ConfiguratorMinimal:slider').getAttribute('data-minimal');
  const $cartItem = $('.ConfiguratorCartItem');
  const $cartOverlay = $('.ConfiguratorCart:overlay');
  const $cart = $('.ConfiguratorCart:');
  const h1 = $('.h1').getAttribute('data-title');
  const minimaldata = JSON.parse($minimal);

  post('cart', {
    data: {
      item: $minimal      
    },
  }).then(({ data }) => {

    dataLayerWishlist(minimaldata, h1, 'add');

    const $cartlist = $('.ConfiguratorCart:center_list');
    $cartlist.innerHTML = data;
    // new LazyLoad();

    addClass($cart, 'is-opencart');
    addClass(document.body, 'is-blocked');
    addClass($cartOverlay, 'is-opencaroverlay');

    deleteItem();
    itemIntheCart();
    displayElementCart();
    // const item = $cartItem.getAttribute('data-cart');
    // if(){}

  });  
}

function displayElementCart() {
  const $info = $('.ConfiguratorCart:btn--info');
  $info.style.display = 'inline-block';
  const $continue = $('.ConfiguratorCart:bottom_continue');
  $continue.style.display = 'block';
}

function removeElementCart() {
  const $info = $('.ConfiguratorCart:btn--info');
  $info.style.display = 'none';
  const $continue = $('.ConfiguratorCart:bottom_continue');
  $continue.style.display = 'none';
}

export function itemIntheCart() {
  const $number = $all('.Wishlist:length');
  const $cartlength = $('.ConfiguratorCartItem:').getAttribute('data-cart');

  forEach($number, (number) => {
    if($cartlength == "0"){
      /**
       * Da capire se chiudere il pannello
       */
      setTimeout(() => {
        const $form = $('.ConfiguratorCart:form');
        removeClass($form, 'is-openform');  
      }, 1000);

      number.style.display = 'none';

    } else {
      number.style.display = 'block';
      number.innerHTML = $cartlength;    
    }
  });

}

export function deleteItem() {
  const $item = $all('.ConfiguratorCartItem:card_trash');

  forEach($item, (item) => {
    const id = item.getAttribute('data-id');
    const titleProduct = $('.ConfiguratorCartItem:card_title').textContent;
    
    on(item, 'click', () => {
      post('cart', {
        data: {
          id: id
        },
      }).then(({ data }) => {
        const $cartlist = $('.ConfiguratorCart:center_list');
        dataLayerWishlist(id, titleProduct, 'remove');
        $cartlist.innerHTML = data;
        // new LazyLoad();        

        deleteItem();
        itemIntheCart();
        const itemCart = $('.ConfiguratorCartItem:').getAttribute('data-cart');
        if(itemCart == '0'){
          removeElementCart();
        }
  
      });
    });
  });
}