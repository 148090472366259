import FormsInputMaterial from "@olmokit/core/forms/input/material";
import FormsSelectMaterial from "@olmokit/core/forms/select/material";
import "@olmokit/core/forms/checkbox";
import FormsTextareaMaterial from "@olmokit/core/forms/textarea/material";
import Olmoforms from "@olmokit/core/olmoforms/feedback";
import Dependency from "@olmokit/core/olmoforms/dependency";
import FormUploadFile from "@olmokit/core/forms/file/olmominimal";
import { $, on } from "@olmokit/dom";
import { post } from "@olmokit/core/fragments/ajax";
import { itemIntheCart, deleteItem } from "components/Configurator";
import { countryEndpoint, hideProject, showProject } from "components/Form";
import { dataLayerFillingForm, dataLayerConfigForm } from "components/Datalayer";
import { salesforceCommunication } from "components/Form";
import { sessionCheck } from "components/Popup";
import "./ProductCart.scss";

/**
 * Component: FormProductCart
 */
export function FormProductCart() {
  console.log("FormProductCart mounted.");

  dataLayerFillingForm('#contactconfigurator .of:el', 'Form Configurator')

  const $container = $('.FormProductCart: .of:country');
  const $country = $('select', $container);

  on($country, 'change', () => {  
    countryEndpoint(".FormProductCart:", $country.value);
  });    

  const $usertype = $('.FormProductCart: .of:usertype');
  const $userfield = $('select', $usertype);
  const $jobcontainer = $('.FormProductCart: .of:job');
  const $jobfield = $('select', $jobcontainer);
  const $projcontainer = $('.FormProductCart: .of:project');
  const $projfield = $('select', $projcontainer);
  const $mqcontainer = $('.FormProductCart: .of:projectmq');
  const $yearcontainer = $('.FormProductCart: .of:projectyear');

  on($usertype, 'change', () => {
    if ($userfield.value == 'private') {
      hideProject($projcontainer, $projfield, $mqcontainer, $yearcontainer);
    } else {
      $projcontainer.style.display = 'block';
      showProject($projcontainer, $projfield, $mqcontainer, $yearcontainer);
    }
  })

  on($jobfield, 'change', () => {   
    if ($jobfield.value == 'Press Stampa') {
      hideProject($projcontainer, $projfield, $mqcontainer, $yearcontainer);
    } else {
      $projcontainer.style.display = 'block';
      showProject($projcontainer, $projfield, $mqcontainer, $yearcontainer);
    }
  });
  on($projfield, 'change', () => {
    showProject($projcontainer, $projfield, $mqcontainer, $yearcontainer);
  })

  const olmoforms = Olmoforms(".FormProductCart:", {
    sent: (formData) => {
      /**
       * Recreate products info
       */      
      //  let products = [];

      //  forEach(productsBlob, (blob) => {
      //    let single = {};
      //    single['product'] = blob.titleproduct;
      //    single['minimal_id'] = blob.id;
      //    products.push(single);
      //  });      

      dataLayerConfigForm("form sent", formData.product, formData, $('#of-6-usertype').value);
      salesforceCommunication(formData, "Form Config");   

      const $minimal = $('.ConfiguratorMinimal:slider').getAttribute('data-minimal');
      const $cartItem = $('.ConfiguratorCartItem');
      const $cartOverlay = $('.ConfiguratorCart:overlay');
      const $cart = $('.ConfiguratorCart:');
    
      post('cart', {
        data: {
          session: true
        },
      }).then(({ data }) => {
        const $cartlist = $('.ConfiguratorCart:center_list');
        $cartlist.innerHTML = data;
    
        deleteItem();
        itemIntheCart();
        // sessionCheck();

      }); 
      // get('sessions').then(({ data }) => {
      //   debugger;
      // });

    },
    failed: (instance) => dataLayerConfigForm("form_error", '', '', $('#of-6-usertype').value)
  });

  FormsInputMaterial();
  Dependency(".FormProductCart:");
  FormsSelectMaterial();
  FormUploadFile();  
  FormsTextareaMaterial();

  return {
    destroy() {
      olmoforms.destroy();
    },
  };


}
