import { $, on, addClass, removeClass } from "@olmokit/dom";
import { globalData } from "@olmokit/core/data";
import { replaceFragment } from "@olmokit/core/fragments";
import { FormNewsletter } from "components/Form";
import scrollLock from "@olmokit/core/scroll/lock";
import "./index.scss";

/**
 * Component: Popup
 */

const popupNL = $(".Popup:");
const closeBtn = $(".Popup:btn");
const closeBg = $(".Popup:bg");

export function Popup() {
  console.log("Popup mounted.");
  
  replaceFragment("popupNewsletter", "components.FormNewsletter").then(() => FormNewsletter());

  on(closeBtn, 'click', () => {
    scrollLock.enable(document.body);
    removeClass(popupNL, "is-in");
    removeClass(closeBg, "is-in");
  });
  
  on(closeBg, 'click', () => {
    scrollLock.enable(document.body);
    removeClass(popupNL, "is-in");
    removeClass(closeBg, "is-in");
  });

  document.addEventListener('keydown', evt => {
    console.log(evt.key);
    if (evt.key === 'Escape') {
        scrollLock.enable(document.body);
        let open = $(".Popup:.is-in");
        open.classList.remove('is-in');
        let openBg = $(".Popup:bg.is-in");
        openBg.classList.remove('is-in');
    }
  });

}

export function openNL() {
  console.log('NL opened')
  sessionStorage.setItem("alreadyopened", "true");
  addClass(popupNL, "is-in");
  setTimeout(() => {
    addClass(closeBg, "is-in");
    scrollLock.disable(document.body);
  },600)
}

// al clic su chiudi pannello richiedi info)
//     controllare il sessionstorage
//         se alreadyopened non esiste
//             se esiste chiave starttimer
//                 cancellare chiave starttimer
//             aprire popup newsletter
//             impostare chiave alreadyopened
//         se alreadyopened esiste già
//             non eseguire alcuna azione perché il popup è già stato aperto
export function sessionCheck() {
  if (!sessionStorage.hasOwnProperty("alreadyopened")) {
    if (sessionStorage.hasOwnProperty("starttimer")) {
      sessionStorage.removeItem('starttimer');
    }
    setTimeout(() => {
      openNL();
    },2000)
  }
}

// al caricamento della pagina singlenews o singleproject 
//     controllare il sessionstorage
//         se alreadyopened esiste già
//             non eseguire alcuna azione perché il popup è già stato aperto
//         se alreadyopened non esiste
//             se esiste chiave starttimer
//                 non eseguire alcuna azione perché bisogna lasciare scadere il timer
//             se startimer non esiste
//                 impostare chiave starttimer con valore url
//                 far partire il timer di 50 secondi
//                 alla scadenza del timer controllare se la chiave starttimer ha il valore della url attuale
//                 se ha il valore della url
//                     cancellare chiave starttimer
//                     aprire popup newsletter
//                     impostare chiave alreadyopened
//                 se non ha il valore della url
//                     non eseguire alcuna azione perché bisogna lasciare scadere il timer
export function timerCheck() {
  console.log('timer started');
  if (!sessionStorage.hasOwnProperty("alreadyopened")) {
    if (!sessionStorage.hasOwnProperty("starttimer")) {
      sessionStorage.setItem("starttimer", window.location.pathname);
      setTimeout(() => {
        if (sessionStorage.getItem("starttimer") === window.location.pathname) {
          sessionStorage.removeItem('starttimer');
          openNL();
        }
        console.log('timer ended');
      },45000)
    } else if (sessionStorage.getItem("starttimer") === window.location.pathname) {
      sessionStorage.removeItem('starttimer');
      openNL();
    }
  }
}
