import { $, $all, forEach, on } from "@olmokit/dom";
import { globalData } from "@olmokit/core/data";
import "./index.scss";

// //////////
// IMPORTANT:
// some events are intercepted and pushed 
// directly by the route or components js, 
// they are not described here
// //////////

/**
 * Component: Datalayer
 */

if (globalData.route == '404') {
  dataLayer.push({
    "event": "404"
  });
}

export function Datalayer() {
  console.log("Datalayer mounted.");
}

export function dataLayerNewsForm(event, formData) {
  dataLayer.push({
    ...formData,
    event: event,
    form: "Form Newsletter",
  });
}

export function dataLayerForm(event, formData, formname, user, request) {
  dataLayer.push({
    ...formData,
    event: event,
    form: formname,
    user_type: user,
    request_type: request,
  });
}

export function dataLayerConfigForm(event, blobs, formData, usertype) {

  const productsBlob = JSON.parse(blobs);
  // debugger;

  let products = [];

  forEach(productsBlob, (property) => {

    let single = {};

    forEach(property, (props) => {
      const prodnameraw = props.id;
      const prodname = prodnameraw.split(']');
      single['product'] = prodname[1].toLowerCase().replace(/ /g, '-').replace('"', '');

      if(props.parent.name == 'color'){
        single['color'] = props.name;
      }
      if(props.parent.name == 'look'){
        single['look'] = props.name;
      }
      if(props.parent.name == 'size'){
        single['size'] = props.name;
      }
      if(props.parent.name == 'finish'){
        single['finish'] = props.name;
      }
      if(props.parent.name == 'thickness'){
        single['thickness'] = props.name;
      }
    });    

    products.push(single);
  }); 

  dataLayer.push({
    ...formData,
    event: event,
    products: products,
    form: "Form Configurator",
    user_type: usertype,
  });

  products = [];
}

export function dataLayerFillingType(field) {
  dataLayer.push({
    'event': 'filling_form',
    'form_name': 'Form Type',
    'form_field': field,
  });
}

export function dataLayerFillingForm(activeclass, formname) {
  const els = $all(activeclass);
  for (let el of els) {
    on(el, 'change', (event) => {
      console.dir(event.target.id);
      // push to datalayer
      dataLayer.push({
        'event': 'filling_form',
        'form_name': formname,
        'form_field': event.target.name,
      });
    }); 
  }
}

export function datalayerProdSlider(activearea, gtmclass, renderings) {
  const area = $all(activearea);
  if (area) {
    forEach(area, (a) => {
      a.addEventListener('click',function(e){
        if (e.target.classList.contains(gtmclass)) {
            // push to datalayer
            // e.preventDefault();
            dataLayer.push({
                'event': 'product_click',
                'product': e.target.getAttribute('data-product'),
                'product_specifications': 'false',
                'renderings': renderings,
            });
        }
      });
    })  
  }
}

export function datalayerProd(prod, specs, renderings) {
  dataLayer.push({
    'event': 'product_click',
    'product': prod,
    'product_specifications': specs,
    'renderings': renderings,
});
}

export function dataLayerWishlist(properties, h1, action) {

  let look = '';
  let color = '';
  let size = '';
  let finish = '';
  let thickness = '';
  const title = h1.toLowerCase().replace(/ /g, '-');

  if(action == 'add'){
    forEach(properties, (property) => {
      if(property.parent.name == 'color'){
        color = property.name;
      }
      if(property.parent.name == 'look'){
        look = property.name;
      }
      if(property.parent.name == 'size'){
        size = property.name;
      }
      if(property.parent.name == 'finish'){
        finish = property.name;
      }
      if(property.parent.name == 'thickness'){
        thickness = property.name;
      }
    });
  } else {
    const $data = $all('.ConfiguratorCartItem:card_props-single'); 

    forEach($data, (props) => {

      if(props.getAttribute('data-id') == properties){
        const value = props.getAttribute('data-value');
        const property = props.getAttribute('data-property');
  
        if(property == 'color'){
          color = value;
        }
        if(property == 'look'){
          look = value;
        }
        if(property == 'size'){
          size = value;
        }
        if(property == 'finish'){
          finish = value;
        }
        if(property == 'thickness'){
          thickness = value;
        }
      }  

    });
  }

  // push here to datalayer
  dataLayer.push({
    'event': 'product_configurator',
    'product': action == 'add' ? h1 : title,
    'action_type': 'wishlist',
    'action': action,
    'look': look,
    'color': color,
    'size': size,
    'finish': finish,
    'thickness': thickness
  });
}

export function dataLayerConfigurator(action, prodname, propname, filtname) {
  dataLayer.push({
    'event': 'product_configurator',
    'product': prodname,
    'configurator_category': propname,
    'configurator_field': filtname,
    'action_type': 'configuration',
    'action': action
  });
}

export function dataLayerGetInfo(blobs) {

  let products = [];

  forEach(blobs, (property) => {

    let single = {};

    forEach(property, (props) => {
      const prodnameraw = props.id;
      const prodname = prodnameraw.split(']');
      single['product'] = prodname[1].toLowerCase().replace(/ /g, '-').replace('"', '');

      if(props.parent.name == 'color'){
        single['color'] = props.name;
      }
      if(props.parent.name == 'look'){
        single['look'] = props.name;
      }
      if(props.parent.name == 'size'){
        single['size'] = props.name;
      }
      if(props.parent.name == 'finish'){
        single['finish'] = props.name;
      }
      if(props.parent.name == 'thickness'){
        single['thickness'] = props.name;
      }
    });    

    products.push(single);
  }); 

  // push here to datalayer
  dataLayer.push({ products: null });
  dataLayer.push({
    'event': 'product_configurator',
    'products': products,
    'action_type': 'get info'
  });

  products = [];
}

export function dataLayerShareOn(social, element, element_clicked) {
  dataLayer.push({
    'event': 'click',
    'event_type': 'share_on',
    'social': social,
    'element': element,
    'element_clicked': element_clicked
  });
}