import "@olmokit/core/debug/api";
import "@olmokit/core/reboot/index.scss";
import "@olmokit/core/grid/index.scss";
import "@olmokit/core/typography/reset.scss";
import "@olmokit/core/icon/index.scss";
// import LazyLoad from "@olmokit/core/lazy";
import "@olmokit/core/img";
import "components/Image";
import { Header } from "components/Header";
import { MenuMobile } from "components/MenuMobile";
import { Prefooter } from "components/Prefooter";
import { Footer } from "components/Footer";
import { Popup } from "components/Popup";
import { ConfiguratorCart } from "components/Configurator/Cart";
import "components/Cta";
import "./index.scss";
import "utils/logo.scss";
import "utils/acanto.scss";
import "utils/btn.scss";
import "utils/fonts.scss";

Header();
MenuMobile();
ConfiguratorCart();
Prefooter();
Footer();
Popup();

let url = window.location.href;
let urlUrl = new URL(url);
let params = new URLSearchParams(urlUrl.search.slice(1));

for (let p of params) {
    if(p[0] == "utm_source"){
        localStorage.setItem('utm_source', p[1]);
    }
}