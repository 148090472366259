import { $ } from "@olmokit/dom";
import BaseHeader from "@olmokit/components/Header";
// import I18nLinks from "@olmokit/core/i18n/links";
import { scrollThreshold } from "@olmokit/core/scroll/threshold";
import { Selectlang } from "components/Selectlang";
import { InputMenu } from "components/Search";
import "components/Hamburger";
import { MenuDesktop } from "components/MenuDesktop";

import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

import "./index.scss";

/**
 * Component: Header
 *
 */

export function Header() {
  console.log("Header mounted.");

  const header = BaseHeader();
  // I18nLinks();

  const search = $(".Header:search");
  if (search) {
    InputMenu();
  }

  Selectlang();

  MenuDesktop();

  scrollThreshold(54, { target: header.$root });

  return {
    /**
     * Set variant
     *
     * @param {"light" | "dark"} variant
     */
    setVariant(variant) {
      const { className } = header.$root;
      header.$root.className = className.replace(
        /(is-).+(\s*)/,
        `$1${variant}$2`
      );
    },
  };
}

//Scrolling header
const showAnim = gsap
  .from(".HeaderUpDown", {
    top: -55,
    paused: true,
    duration: 0.3,
  })
  .progress(1);

ScrollTrigger.create({
  start: "top top",
  end: 99999,
  onUpdate: (self) => {
    self.direction === -1 ? showAnim.play() : showAnim.reverse();
  },
});
