import {breakpoints } from "@olmokit/core/scss";
import {
  glide,
  Anchors,
  Autoplay,
  Breakpoints,
  Controls,
  // LazyLoad,
  AutoHeight,
  Swipe,
} from "@olmokit/core/glide";
import "@olmokit/core/glide/bullets";
import "@olmokit/core/glide/arrows";
import "./index.scss";

/**
* Component: HomeSlider
*/

export default function slider(rootSelector) {
  
  const $container = `${rootSelector ? rootSelector + " " : ""}`;
  // console.log(breakpoints.lg);
  const slider = glide(
    $container,
    {
      loop: true,
      autoplay: 4500,
      hoverpause: true,
      animationDuration: 1000,
      killWhen: { above: 99999 },
      bound: true,
      autoHeight: false,
      breakpoints: {
        [breakpoints.over]: {
          perView: 1,
          gap: 0,
          peek: { before: 0, after: 0 },
        },
        [breakpoints.md]: {
          perView: 1,
          gap: 0,
          peek: { before: 0, after: 0 },
        },
      },
    },
    { Anchors, 
      Autoplay, 
      Breakpoints, 
      Controls,
      // LazyLoad, 
      AutoHeight,
      Swipe }
      );
      
      return slider;
      
    }

export function Homeslider() {
  import("@olmokit/core/player").then(({ Player }) => {
    Player();    
  });
}
