import FormsInputMaterial from "@olmokit/core/forms/input/material";
import FormsSelectMaterial from "@olmokit/core/forms/select/material";
import "@olmokit/core/forms/checkbox";
import FormsTextareaMaterial from "@olmokit/core/forms/textarea/material";
import Olmoforms from "@olmokit/core/olmoforms/feedback";
import Dependency from "@olmokit/core/olmoforms/dependency";
import FormUploadFile from "@olmokit/core/forms/file/olmominimal";
import { $, on } from "@olmokit/dom";
import { countryEndpoint, hideProject, showProject } from "components/Form";
import { dataLayerFillingForm, dataLayerForm } from "components/Datalayer";
import { salesforceCommunication } from "components/Form";
import "./Other.scss";

/**
 * Component: FormProductCart
 */
export function FormOther() {
  console.log("FormProduct mounted.");

  dataLayerFillingForm('#contactother .of:el', 'Form Other')

  const $container = $('.FormOther: .of:country');
  const $country = $('select', $container);

  on($country, 'change', () => {  
    countryEndpoint(".FormOther:", $country.value);
  });  

  const $jobcontainer = $('.FormOther: .of:job');
  const $jobfield = $('select', $jobcontainer);
  const $projcontainer = $('.FormOther: .of:project');
  const $projfield = $('select', $projcontainer);
  const $mqcontainer = $('.FormOther: .of:projectmq');
  const $yearcontainer = $('.FormOther: .of:projectyear');

  on($jobfield, 'change', () => {   
    if ($jobfield.value == 'Press Stampa') {
      hideProject($projcontainer, $projfield, $mqcontainer, $yearcontainer);
    } else {
      $projcontainer.style.display = 'block';
      showProject($projcontainer, $projfield, $mqcontainer, $yearcontainer);
    }
  });
  on($projfield, 'change', () => {
    showProject($projcontainer, $projfield, $mqcontainer, $yearcontainer);
  })

  const olmoforms = Olmoforms(".FormOther:", {
    sent: (formData) => {
      dataLayerForm("form sent", formData, "Form Other", $('#of-3-usertype').value, $('#of-3-requesttype').value);
      console.log(formData);
      debugger;
      salesforceCommunication(formData, "Form Other");
    }, 
    failed: (instance) => dataLayerForm("form_error", '', "Form Other", $('#of-3-usertype').value, $('#of-3-requesttype').value)
  });

  FormsInputMaterial();
  Dependency(".FormOther:");
  FormsSelectMaterial();
  FormUploadFile();  
  FormsTextareaMaterial();

  return {
    destroy() {
      olmoforms.destroy();
    },
  };


}
