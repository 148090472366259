import {breakpoints, gridGutter } from "@olmokit/core/scss";
import { $, $all, forEach, addClass } from "@olmokit/dom";
import {
  glide,
  Anchors,
  Autoplay,
  Breakpoints,
  Controls,
  // LazyLoad,
  Swipe,
} from "@olmokit/core/glide";
import "@olmokit/core/glide/arrows";
import { CardProject } from "components/CardProject";
 import { CardBlog } from "components/CardBlog";
import "./index.scss";

/**
* Component: VisualProjects
*/
export default function projects(rootSelector) {
  
  let slidelength = $(rootSelector).getAttribute('data-slide');
  let cardtype = $(rootSelector).getAttribute('data-card');
  const width = window.innerWidth;
  // console.log(rootSelector+'; slidelength:'+slidelength+' width:'+width);
  
  if (cardtype == 'cardblog') {
    CardBlog();
  } else {
    CardProject();
  }

  if (parseInt(slidelength) == 1 ) {
    notSlider(rootSelector);
  } else if (parseInt(slidelength) == 2 ) {
    if (width <= 480) {
      startSlider(rootSelector);  
    } else {
      notSlider(rootSelector);
    }
  } else if (parseInt(slidelength) == 2 ) {
    if (width <= breakpoints.sm) {
      startSlider(rootSelector);  
    } else {
      notSlider(rootSelector);
    }
  } else if (parseInt(slidelength) == 3 ) {
    if (width <= breakpoints.lg) {
      startSlider(rootSelector);  
    } else {
      notSlider(rootSelector);
    }
  } else if (parseInt(slidelength) >= 4 ) {
    startSlider(rootSelector);
  }
}

function notSlider(element) {
  // console.log('not slider');
  const root = $(element);
  addClass(root, 'not-slider');
}

function startSlider(element) {
  // console.log('slider');
  const $container = `${element ? element + " " : ""}`;
  const slider = glide(
    $container, {
      loop: true,
      autoplay: 4500,
      hoverpause: true,
      animationDuration: 900,
      killWhen: { above: 99999 },
      bound: true,
      breakpoints: {
        [breakpoints.over]: {
          perView: 3,
          gap: gridGutter,
          peek: { before: gridGutter / 2, after: gridGutter / 2 },
        },
        [breakpoints.xxl]: {
          perView: 3,
          gap: gridGutter,
          peek: { before: gridGutter / 2, after: gridGutter / 2 },
        },
        [breakpoints.xl]: {
          perView: 3,
          gap: gridGutter,
          peek: { before: gridGutter / 2, after: gridGutter * 2 },
        },
        [breakpoints.lg]: {
          perView: 2,
          gap: gridGutter,
          peek: { before: gridGutter / 2, after: gridGutter * 2 },
        },
        [breakpoints.md]: {
          perView: 2,
          gap: gridGutter,
          peek: { before: gridGutter / 2, after: gridGutter * 2 },
        },
        [breakpoints.sm]: {
          perView: 1,
          gap: gridGutter,
          peek: { before: gridGutter / 2, after: gridGutter * 2 },
        }
      },
    }, { 
      Anchors, 
      Autoplay, 
      Breakpoints, 
      Controls,
      // LazyLoad, 
      Swipe 
    }
    );
    return slider;
  }
  