import FormsInputMaterial from "@olmokit/core/forms/input/material";
import FormsSelectMaterial from "@olmokit/core/forms/select/material";
import "@olmokit/core/forms/checkbox";
import FormsTextareaMaterial from "@olmokit/core/forms/textarea/material";
import Olmoforms from "@olmokit/core/olmoforms/feedback";
import Dependency from "@olmokit/core/olmoforms/dependency";
import { $, $all, addClass, on, removeClass } from "@olmokit/dom";
import { post } from "@olmokit/core/fragments/ajax";
import { FormProductDetail } from "./ProductDetail";
import { FormProductProps } from "components/Form/ProductDetail/Property";
import { globalConf } from "@olmokit/core/data";
import { countryEndpoint, hideProject, showProject } from "components/Form";
import axios from 'axios';
import { dataLayerFillingForm, dataLayerForm } from "components/Datalayer";
import { salesforceCommunication } from "components/Form";
import "./Product.scss";

/**
 * Component: FormProductCart
 */

let counter = 0;
let olmoforms;

export function FormProduct() {
  console.log("FormProduct mounted.");

  dataLayerFillingForm('#contact .of:el', 'Form Product')

  const $container = $('.FormProduct: .of:country');
  const $country = $('select', $container);

  on($country, 'change', () => {
    countryEndpoint(".FormProduct:", $country.value);
  });

  const $jobcontainer = $('.FormProduct: .of:job');
  const $jobfield = $('select', $jobcontainer);
  const $projcontainer = $('.FormProduct: .of:project');
  const $projfield = $('select', $projcontainer);
  const $mqcontainer = $('.FormProduct: .of:projectmq');
  const $yearcontainer = $('.FormProduct: .of:projectyear');

  on($jobfield, 'change', () => {
    if ($jobfield.value == 'Press Stampa') {
      hideProject($projcontainer, $projfield, $mqcontainer, $yearcontainer);
    } else {
      $projcontainer.style.display = 'block';
      showProject($projcontainer, $projfield, $mqcontainer, $yearcontainer);
    }
  });
  on($projfield, 'change', () => {
    showProject($projcontainer, $projfield, $mqcontainer, $yearcontainer);
  })

  olmoforms = Olmoforms(".FormProduct:", {
    sent: (formData) => {
      dataLayerForm("form sent", formData, "Form Product", $('#of-3-usertype').value, $('#of-3-requesttype').value);
      axios.post(globalConf.cmsApiUrl + '/' + globalConf.locale + '/salesforce/access', formData)
        .then(() => {
          console.log('salesforce done');
        })
        .catch((error) => {
          salesforceCommunication(formData, "Form Config");
          console.log(error);
        });
    },
    failed: (instance) => dataLayerForm("form_error", '', "Form Product", $('#of-3-usertype').value, $('#of-3-requesttype').value)
  });

  FormsInputMaterial();
  Dependency(".FormProduct:");
  FormsSelectMaterial();
  FormsTextareaMaterial();


  FormProductDetail();

  const $product = $('#of-9-product');
  on($product, 'change', (event) => {
    debugger;
    makeThePostParams(0, event.target.value);
    FormsSelectMaterial();
    const $productInput = $('#of-9-products');
    let array = [];
    let obj = {
      product: event.target.value
    };
    array.push(obj);
    $productInput.value = JSON.stringify(array);
  });

  // makeThePost(); 

  return {
    destroy() {
      olmoforms.destroy();
    },
  };
}

export function makeThePostParams(index, name) {
  post('productpropform', {
    data: {
      id: index,
      name: name
    },
  }).then(({ data }) => {
    // const $productpros = $('.FormProductDetail:property'+index);
    const $productpros = $('.of:productspace');
    $productpros.innerHTML = data;
    FormProductProps();
  });

}

export function makeThePost() {
  post('productform', {
    data: {
      id: counter
    },
  }).then(({ data }) => {
    const detail = $('.productCoutnauner');
    if (detail) {

      const productDetails = $all('.productCoutnauner');
      let container = document.createElement("div");
      addClass(container, 'productCoutnauner');
      productDetails[productDetails.length - 1].after(container);
      const productDetailsNew = $all('.productCoutnauner');
      productDetailsNew[productDetails.length].innerHTML = data;

    } else {

      const $productspace = $('.of:productspace');
      let container = document.createElement("div");
      addClass(container, 'productCoutnauner');
      $productspace.appendChild(container);
      const $productCoutnauner = $('.productCoutnauner');
      $productCoutnauner.innerHTML = data;

    }
    FormProductDetail();
  });

  counter++;
} 