import "components/NewLook";
import "./index.scss";
import { $, $all, on, forEach } from "@olmokit/dom";
import { Ajax } from "@olmokit/core/ajax";
import { globalConf } from "@olmokit/core/data";
import { render } from "@olmokit/utils/render";
import { datalayerProd } from "components/Datalayer";

/**
* Component: CardProduct
*/
export function CardProduct() {
  console.log("CardProduct mounted.");
  
  const cardImgs = $all(".CardProduct:img");
  const cardBtns = $all(".CardProduct:cta");
  
  if (cardImgs) {
    for(let i=0; i < cardImgs.length; i++){
      on(cardImgs[i], "click", () => {
        let dest = cardImgs[i].getAttribute('data-href');
        if (cardImgs[i].getAttribute('data-product') != '') {
          datalayerProd(cardImgs[i].getAttribute('data-product'), 'false', 'false');
        }
        window.location.href = dest;
      });
    }
  }
  if (cardBtns) {
    for(let i=0; i < cardBtns.length; i++){
      on(cardBtns[i], "click", () => {
        let dest = cardBtns[i].getAttribute('data-href');
        if (cardBtns[i].getAttribute('data-product') != '') {
          datalayerProd(cardBtns[i].getAttribute('data-product'), 'false', 'false');
        }
        window.location.href = dest;
      });
    }
  }
  
}

export function CardProductProps() {
  const $checkTheLook = $all(".checkTheLook");
  const $minimal = $all(".CardProduct:minimal");
  const renderProductMinimal = render($("#product-minimals").innerHTML);
  let minimals = [];
  
  forEach($checkTheLook, (card, index) => {
    on(card, "click", (e) => {
      // e.preventDefault();
      
      const props = getPropertyParams();
      
      const prodName = card.getAttribute("data-productname");
      const prodId = card.getAttribute("data-productid");
      
      Ajax(
        globalConf.cmsApiUrl +
        "/" +
        globalConf.locale +
        "/filterminimalproperty",
        {
          method: "POST",
          data: {
            minimal: true,
            productid: prodId,
            values: props,
            fields: ["id", "name_txt_general", "property_props_general"],
          },
        }
        ).then(({ data }) => {
          console.log(data);
          forEach(data.minimal, (minimal) => {
            let objMinimal = {};
            let propsUrl = "";
            let propsName = "";
            let size = "";
            let thickness = "";
            let finish = "";
            
            forEach(minimal.property, (property) => {
              if (
                property.parent.name == "size" ||
                property.parent.name == "finish" ||
                property.parent.name == "thickness" ||
                property.parent.name == "natura-technologies" ||
                property.parent.name == "pattern"
                ) {
                  if (!propsName == "") {
                    propsName += " " + property.code;
                  } else {
                    propsName += property.code;
                  }
                }
                
                if (property.parent.name == "size") {
                  size = property.name;
                }
                if (property.parent.name == "thickness") {
                  thickness = property.name;
                }
                if (property.parent.name == "finish") {
                  finish = property.name;
                }
                
                if (!propsUrl == "") {
                  propsUrl += "&" + property.parent.id + "=" + property.id;
                } else {
                  propsUrl += "?" + property.parent.id + "=" + property.id;
                }
              });
              
              objMinimal["slug"] = data.productslug;
              objMinimal["name"] = propsName;
              objMinimal["url"] = propsUrl;
              objMinimal["size"] = size;
              objMinimal["thickness"] = thickness;
              objMinimal["finish"] = finish;
              
              minimals.push(objMinimal);
            });
            
            $minimal[index].innerHTML = renderProductMinimal(minimals);
            card.style.display = "none";
            minimals = [];
          });
        });
      });
    }
    
    /**
    * Setup the property store
    * */
    let property = {};
    
    function getPropertyParams() {
      let url = window.location.href;
      let urlUrl = new URL(url);
      let params = new URLSearchParams(urlUrl.search.slice(1));
      
      let array;
      let valuesObj = [];
      let val = {};
      
      for (let p of params) {
        val[p[0]] = p[1];
        let rawArray = JSON.parse("[" + p[1] + "]");
        val[p[0]] = rawArray;
        array = rawArray;
        
        property = val;
      }
      
      let queryValues = [];
      
      for (const [key, value] of Object.entries(property)) {
        queryValues.push(JSON.parse("[" + value + "]"));
      }
      
      console.log("property", property);
      console.log("queryValues", queryValues);
      
      if (queryValues) {
        return JSON.stringify(queryValues);
      } else {
        return "";
      }
    }
