import { $, on, addClass, removeClass } from "@olmokit/dom";
import { post } from "@olmokit/core/fragments/ajax";
import { FormProductCart } from "components/Form";
import { dataLayerGetInfo } from "components/Datalayer";
import "./CartItem.scss";

/**
 * Component: ConfiguratorCartItem
 */

export function ConfiguratorCartItem() {
  console.log("ConfiguratorCartItem mounted.");
  const $cartitem = $('.ConfiguratorCart:btn--info');
  const $form = $('.ConfiguratorCart:form');  
  
  on($cartitem, 'click', () => {    
    
    post('formproduct', {
      data: {
        item: '$minimal'
      },
    }).then(({ data }) => {      

      const $cartlist = $('.ConfiguratorCart:form_container');
      $cartlist.innerHTML = data;
      
      addClass($form, 'is-openform');
      
      FormProductCart();

      const $arrowback = $('.FormProductCart:title-arrow');
      on($arrowback, 'click', () => {
        removeClass($form, 'is-openform');
      });

      getSession();

    });

  });


}

function getSession() {

  post('sessioncart').then(({ data }) => {
    // datalayer for get info
    const blobs = data;
    const blobslength = data.length;

    // for(let i = 0; i < blobslength; i++){
    //   let single = {};
    //   single['product'] = blobs[i].titleproduct;
    //   single['minimal_id'] = blobs[i].id;
    //   products.push(single);
    // }   
    
    dataLayerGetInfo(blobs);        


  });  
  
}